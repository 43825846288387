<template>
  <div>
    <div class="vd-h4 vd-light-blue vd-margin-bottom-30">
      <strong>Invoices</strong>
    </div>

    <table class="vms-invoices__table table table-sm">
      <thead>
        <tr>
          <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
            Invoice ID
          </th>
          <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
            Scheduled Date
          </th>
          <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
            Due Date
          </th>
          <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
            Price (ex GST)
          </th>
          <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
            Status
          </th>
          <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
            PO #
          </th>
          <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
            Actions
          </th>
        </tr>
      </thead>
      <tbody v-for="(orderDetails, index) in invoiceList" :key="index">
        <project-invoices-row
          v-for="(invoiceDetail, rowIndex) in orderDetails.invoices"
          :key="rowIndex"
          :invoice-detail="invoiceDetail"
          :order-detail="orderDetails"
        ></project-invoices-row>
      </tbody>
    </table>

    <add-po-modal v-if="showAddPoNumberModal"></add-po-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectInvoicesRow from '@views/project/invoices/project-invoices-list/project-invoices-row'
import AddPoModal from '@views/project/invoices/components/project-invoices-add-po-modal'

export default {
  components: {
    ProjectInvoicesRow,
    AddPoModal,
  },

  computed: {
    ...mapGetters({
      invoiceList: 'project/invoice/invoiceList',
      showAddPoNumberModal: 'project/invoice/showAddPoNumberModal',
    }),
  },
}
</script>
