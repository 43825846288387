<template>
  <div
    class="vms-dropdown vms-invoices__actions--dropdown vd-box-shadow vd-background-white vd-border-radius-6"
  >
    <div>
      <a
        v-if="!isMobileScreen && invoiceDetails.invoice_url"
        :href="invoiceDetails.invoice_url"
        target="_blank"
        rel="noopener"
        class="vms-dropdown__list vms-invoices__actions--dropdown-list vd-black vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium"
      >
        View
      </a>

      <div
        v-if="!hideSendButton"
        class="vms-dropdown__list vms-invoices__actions--dropdown-list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium"
        @click="handleSaveButton"
      >
        Send
      </div>

      <div
        v-if="!hasInvoice"
        class="vms-dropdown__list vms-invoices__actions--dropdown-list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium"
        @click="handleLinkInvoiceButton"
      >
        Link Invoice
      </div>

      <div
        v-if="!hideDeleteButton"
        class="vms-dropdown__list vms-invoices__actions--dropdown-list vd-padding-top-small vd-padding-bottom-small vd-padding-left-medium vd-padding-right-medium"
        @click="handleDeleteInvoice"
      >
        Delete
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { forEach, isNil, isEmpty, includes } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'
import SweetAlert from '@plugins/sweet-alert'
import VmsConstants from '@configs/vms-constants'

export default {
  mixins: [FiltersMixin],

  props: {
    invoiceDetails: {
      type: Object,
      required: true,
    },

    orderDetails: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasInvoice() {
      return this.invoiceDetails.id && !isNil(this.invoiceDetails.id)
    },

    invoiceId() {
      return this.invoiceDetails.invoice_manual_id
        ? this.invoiceDetails.invoice_manual_id
        : this.invoiceDetails.invoice_number
    },

    /**
     * Hide button when:
     * 1. No invoice, but invoice term is fortnightly.
     * 2. Invoice exist, but status is NOT sent/paid.
     *
     * @returns {boolean}
     */
    hideSendButton() {
      return (
        (!this.hasInvoice &&
          this.orderDetails.order_invoicing_term_id ===
            VmsConstants.orderInvoiceTerms
              .ORDER_INVOICING_TERM_ID_FORTNIGHTLY) ||
        (this.hasInvoice &&
          this.invoiceDetails.invoice_status_id !==
            VmsConstants.invoiceStatuses.XERO_INVOICE_STATUS_ID_SENT &&
          this.invoiceDetails.invoice_status_id !==
            VmsConstants.invoiceStatuses.XERO_INVOICE_STATUS_ID_PAID)
      )
    },

    sendButtonText() {
      let htmlText = ''
      let emailList = ''

      if (this.invoiceDetails.id) {
        htmlText = `<div class="vd-margin-top-small">Are you sure you want to re-send this invoice?</div><div class="vd-margin-top-large"><strong>${
          this.invoiceId
        } for ${this.$options.filters.moneyFormat(
          this.invoiceDetails.price
        )}</strong></div>`
      } else {
        htmlText = `<div class="vd-margin-top-small">Are you sure you want to send this invoice?</div><div class="vd-margin-top-large"><strong>${this.$options.filters.moneyFormat(
          this.invoiceDetails.price
        )}</strong></div>`
      }

      if (!isEmpty(this.project.invoice_contacts)) {
        emailList += `<div class="vd-margin-top-large">Sending to:</div><div class="vd-margin-top-large">`

        forEach(this.project.invoice_contacts, (contact) => {
          emailList += `<strong>${contact.email}<br>`
        })

        emailList += `</strong></div>`
      }

      return htmlText + emailList
    },

    hideDeleteButton() {
      return (
        !this.hasInvoice ||
        this.orderDetails.order_invoicing_term_id ===
          VmsConstants.orderInvoiceTerms.ORDER_INVOICING_TERM_ID_FORTNIGHTLY
      )
    },

    allowInvoiceDeleteInXero() {
      let invoiceDeletableStatus = [
        VmsConstants.invoiceStatuses.XERO_INVOICE_STATUS_ID_NEW,
        VmsConstants.invoiceStatuses.XERO_INVOICE_STATUS_ID_PREPROCESSED,
        VmsConstants.invoiceStatuses.XERO_INVOICE_STATUS_ID_VERIFYING,
      ]

      let orderInvoiceDeletableStatus = [
        VmsConstants.orderInvoiceStatuses.ORDER_UNDER_50_INVOICING,
        VmsConstants.orderInvoiceStatuses.ORDER_UNDER_100_INVOICING,
      ]

      return (
        includes(
          orderInvoiceDeletableStatus,
          this.orderDetails.order_invoice_status_id
        ) &&
        includes(invoiceDeletableStatus, this.invoiceDetails.invoice_status_id)
      )
    },

    deleteInvoiceAlertContent() {
      let headingText = this.allowInvoiceDeleteInXero
        ? `Are you sure you want to delete this invoice?<br>This will also DELETE the invoice in Xero.`
        : `Are you sure you want to delete this invoice?`
      let warningText = this.allowInvoiceDeleteInXero
        ? ``
        : `<div class="vd-margin-top-large vd-red"><strong>Important! Ensure you also delete the invoice from Xero.</strong></div>`

      return `
        <div class="vd-margin-top-small">${headingText}</div>
        <div class="vd-margin-top-large"><strong>${
          this.invoiceId
        } for ${this.$options.filters.moneyFormat(
        this.invoiceDetails.price
      )}</strong></div>
        ${warningText}
      `
    },
  },

  methods: {
    ...mapActions({
      sendInvoice: 'project/invoice/sendInvoice',
      toggleLinkInvoiceShowModal: 'project/invoice/toggleLinkInvoiceShowModal',
      updateLinkInvoiceOrderIdModal:
        'project/invoice/updateLinkInvoiceOrderIdModal',
      deleteProjectInvoice: 'project/invoice/deleteProjectInvoice',
    }),

    handleSaveButton() {
      SweetAlert.fire({
        title: this.invoiceDetails.id ? 'Re-send Invoice' : 'Send Invoice',
        html: this.sendButtonText,
        showCancelButton: true,
        confirmButtonText: 'Send',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            order_id: this.orderDetails.order_id,
            project_id: this.project.id,
            xero_invoice_id: this.invoiceDetails.id,
            includes: null,
          }

          this.sendInvoice(payload)
        }
      })
    },

    handleLinkInvoiceButton() {
      this.toggleLinkInvoiceShowModal()
      this.updateLinkInvoiceOrderIdModal(this.orderDetails.order_id)
    },

    handleDeleteInvoice() {
      SweetAlert.fire({
        title: 'Delete Invoice',
        html: this.deleteInvoiceAlertContent,
        showCancelButton: true,
        confirmButtonText: 'Delete Invoice',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteProjectInvoice({
            project_id: this.project.id,
            invoice_id: this.invoiceDetails.id,
          })
        }
      })
    },
  },
}
</script>
