<template>
  <default-modal
    class="show"
    modal-dialog-class="modal-md"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h3 vd-black">Add PO number to your invoice</div>
      <div class="vms-modal__header--close close vd-black">
        <span
          v-if="!addPoNumberLoading"
          id="closeModalSpan"
          @click="toggleAddPoNumberShowModal"
          >x</span
        >
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div>
        <div class="vd-padding-20 vd-padding-top-0">
          <div class="row">
            <div class="col-12">
              <div class="vd-margin-bottom-20">
                <input-component
                  type="text"
                  name="referenceNumber"
                  label="PO Number"
                  :required="true"
                  :container-custom-classes="[
                    setInputValidationClass('referenceNumber'),
                  ]"
                  @formInput="handleInputUpdate($event, 'referenceNumber')"
                ></input-component>
              </div>
            </div>
            <div class="col-12 vd-text-align-right">
              <button
                id="savePoNumberButton"
                class="vd-btn-small vd-btn-blue"
                :disabled="!referenceNumber || addPoNumberLoading"
                @click="handleAddPoBtn"
              >
                {{ addPoNumberLoading ? 'Loading...' : 'Save' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import FormMixin from '@mixins/forms-mixin'
import InputComponent from '@components/form/form-input'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    DefaultModal,
    InputComponent,
  },

  mixins: [FormMixin],

  data() {
    return {
      referenceNumber: '',
    }
  },

  computed: {
    ...mapGetters({
      addPoNumberOrderId: 'project/invoice/addPoNumberOrderId',
      addPoNumberLoading: 'project/invoice/addPoNumberLoading',
    }),
  },

  validations: {
    referenceNumber: {
      required,
    },
  },

  methods: {
    ...mapActions({
      toggleAddPoNumberShowModal: 'project/invoice/toggleAddPoNumberShowModal',
      addPoNumber: 'project/invoice/addPoNumber',
    }),

    handleAddPoBtn() {
      const orderId = this.addPoNumberOrderId
      const payload = {
        order_id: orderId,
        reference_number: this.referenceNumber,
      }

      this.addPoNumber(payload)
    },
  },
}
</script>
