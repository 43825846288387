<template>
  <default-modal
    :class="['show', isMobileScreen ? 'vms-modal--fullscreen' : '']"
    modal-dialog-class="modal-md"
    content-class="vd-background-white "
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">Link Invoice</div>
      <div class="vms-modal__header--close close vd-black">
        <span v-show="!linkInvoiceLoading" @click="toggleLinkInvoiceShowModal">
          x
        </span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div class="row vd-padding-medium">
        <div class="col-12">
          <div class="vd-margin-bottom-20">
            <input-component
              type="text"
              name="xeroIdentifier"
              label="Xero identifier"
              :required="true"
              :container-custom-classes="[
                setInputValidationClass('xeroIdentifier'),
              ]"
              @formInput="handleInputUpdate($event, 'xeroIdentifier')"
            ></input-component>
          </div>
        </div>
        <div class="col-12 vd-text-align-right">
          <button
            class="vd-btn-small vd-btn-blue"
            :disabled="submitBtnDisabled"
            @click="handleLinkInvoiceBtn"
          >
            {{ linkInvoiceLoading ? 'Loading...' : 'Link Invoice' }}
          </button>
        </div>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import FormMixin from '@mixins/forms-mixin'
import InputComponent from '@components/form/form-input'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    DefaultModal,
    InputComponent,
  },

  mixins: [FormMixin],

  data() {
    return {
      xeroIdentifier: '',
    }
  },

  computed: {
    ...mapGetters({
      linkInvoiceLoading: 'project/invoice/linkInvoiceLoading',
      linkInvoiceOrderId: 'project/invoice/linkInvoiceOrderId',
      project: 'project/projectDetails',
      isMobileScreen: 'common/isMobileScreen',
    }),

    submitBtnDisabled() {
      return this.$v.$invalid || this.linkInvoiceLoading
    },
  },

  validations: {
    xeroIdentifier: {
      required,
    },
  },

  methods: {
    ...mapActions({
      toggleLinkInvoiceShowModal: 'project/invoice/toggleLinkInvoiceShowModal',
      addProductionCost: 'project/invoice/addProductionCost',
      linkInvoice: 'project/invoice/linkInvoice',
    }),

    handleLinkInvoiceBtn() {
      const payload = {
        project_id: this.project.id,
        xero_identifier: this.xeroIdentifier,
        order_id: this.linkInvoiceOrderId,
        includes: null,
      }

      this.linkInvoice(payload)
    },
  },
}
</script>
