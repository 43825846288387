var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vms-invoices-list__item vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium vd-margin-top-medium"},[_c('div',{staticClass:"vms-invoices-list__item--header row no-gutters"},[_c('div',{class:[
        'vms-invoices-list__icon vms-invoices-list__icon--small vd-margin-right-large',
        _vm.showSchedule
          ? ' vd-icon-arrow-up-grey-black'
          : ' vd-icon-arrow-down-grey-black' ],on:{"click":_vm.toggleSchedule}}),_c('div',{staticClass:"vms-invoices-list__item--invoice-id",on:{"click":_vm.toggleSchedule}},[_c('div',{staticClass:"vd-darker-grey"},[_vm._v("Invoice ID")]),_c('div',[(_vm.invoiceDetail.invoice_url)?_c('span',{staticClass:"vd-light-blue",attrs:{"href":_vm.invoiceDetail.invoice_url}},[_vm._v(" "+_vm._s(_vm.invoiceDetail.invoice_manual_id || _vm.invoiceDetail.invoice_number)+" ")]):_c('span',[_vm._v("--")])])]),(_vm.invoiceDetail.invoice_url)?_c('a',{staticClass:"vd-a",attrs:{"href":_vm.invoiceDetail.invoice_url,"target":"_blank","rel":"noopener"}},[_c('span',{staticClass:"vms-invoices-list__icon vd-icon-eye"})]):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeInvoiceDropdown),expression:"closeInvoiceDropdown"}],staticClass:"vd-position-relative"},[(_vm.canShowInvoiceDropdown)?_c('span',{staticClass:"vms-invoices-list__icon vd-icon-three-dot vd-margin-left-small",on:{"click":_vm.toggleInvoiceDropdown}}):_vm._e(),(_vm.showInvoiceDropdown)?_c('invoices-list-dropdown',{attrs:{"invoice-details":_vm.invoiceDetail,"order-details":_vm.orderDetail},on:{"click":_vm.closeInvoiceDropdown}}):_vm._e()],1)]),_c('hr',{staticClass:"vd-border-grey"}),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"vd-margin-right-small"},[_c('div',{staticClass:"vd-darker-grey small"},[_vm._v("Price (ex GST)")]),_c('div',[_vm._v(_vm._s(_vm._f("moneyFormat")(_vm.invoiceDetail.price)))])])]),_c('div',{staticClass:"col-4 vd-text-align-center"},[(_vm.isToBeInvoiced)?_c('span',[_vm._v(" "+_vm._s(_vm.invoiceStatus)+" ")]):_c('div',{class:[
          'vd-margin-right-small vd-border-radius-50 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-small vd-padding-right-small vd-margin-top-small',
          _vm.statusClass ]},[_vm._v(" "+_vm._s(_vm.invoiceStatus)+" ")])]),_c('div',{staticClass:"col-4 vd-text-align-right"},[(_vm.orderDetail.po_number)?_c('div',[_vm._v(" "+_vm._s(_vm.orderDetail.po_number)+" ")]):(!_vm.isPaid && !_vm.showPoForm)?_c('button',{staticClass:"vms-add-po-btn vd-btn-grey vd-padding-extra-small vd-padding-left-small vd-padding-right-small vd-light-blue small vd-margin-top-small",on:{"click":_vm.togglePoForm}},[_vm._v(" + Add PO # ")]):_vm._e()])]),(_vm.showPoForm)?_c('div',{staticClass:"vd-margin-top-medium"},[_c('div',{staticClass:"vd-background-lightest-grey vd-padding-medium vd-border-radius-6"},[_c('div',{staticClass:"vd-text-align-right"},[_c('span',{staticClass:"vd-icon-close vms-invoices-list__icon--small",attrs:{"id":"closeModalSpan"},on:{"click":_vm.togglePoForm}})]),_c('input-component',{attrs:{"type":"text","name":"referenceNumber","label":"Add/Update PO Number","required":true,"container-custom-classes":[
          _vm.setInputValidationClass('referenceNumber') ]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'referenceNumber')}}}),_c('div',{staticClass:"vd-margin-top-medium vd-text-align-right"},[_c('button',{staticClass:"vd-btn-small vd-btn-blue",attrs:{"disabled":!_vm.referenceNumber || _vm.addPoNumberLoading},on:{"click":_vm.savePo}},[_vm._v(" "+_vm._s(_vm.addPoNumberLoading ? 'Loading...' : 'Save PO#')+" ")])])],1)]):_vm._e(),(_vm.showSchedule)?_c('div',[_c('hr',{staticClass:"vd-border-grey"}),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"vd-darker-grey small"},[_vm._v("Scheduled Date")]),(_vm.invoiceDetail.scheduled_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("unixToFormat")(_vm.invoiceDetail.scheduled_date,'DD MMM YYYY'))+" ")]):_c('div',[_vm._v("--")])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"vd-darker-grey small"},[_vm._v("Due Date")]),(_vm.invoiceDetail.due_date)?_c('div',[_vm._v(" "+_vm._s(_vm._f("unixToFormat")(_vm.invoiceDetail.due_date,'DD MMM YYYY'))+" ")]):_c('div',[_vm._v("--")])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }