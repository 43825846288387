<template>
  <div class="vd-padding-medium">
    <default-loader
      v-if="invoiceListLoading || invoiceOverviewLoading"
    ></default-loader>

    <project-invoice-empty-state
      v-else-if="!hasInvoice"
      empty-state-message="This project has no invoices, you can press the + button to add details"
    ></project-invoice-empty-state>

    <template v-else>
      <project-invoices-list-mobile
        v-if="isMobileScreen"
      ></project-invoices-list-mobile>

      <project-invoices-list-desktop
        v-else
        :class="[
          hasInvoice
            ? 'vms-invoices__content--wrapper-fixed'
            : 'vms-invoices__content--wrapper',
        ]"
      ></project-invoices-list-desktop>
    </template>

    <link-invoice-modal v-if="showLinkInvoiceModal"></link-invoice-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { forEach } from 'lodash'
import DefaultLoader from '@components/loaders/default-loader'
import LinkInvoiceModal from '@views/project/invoices/components/project-invoices-link-invoice-modal'
import ProjectInvoicesListDesktop from '@views/project/invoices/project-invoices-list/project-invoices-list-desktop'
import ProjectInvoicesListMobile from '@views/project/invoices/project-invoices-list/project-invoices-list-mobile'
import ProjectInvoiceEmptyState from '@views/project/invoices/components/project-invoices-empty-state'

export default {
  components: {
    DefaultLoader,
    LinkInvoiceModal,
    ProjectInvoicesListDesktop,
    ProjectInvoicesListMobile,
    ProjectInvoiceEmptyState,
  },

  computed: {
    ...mapGetters({
      invoiceListLoading: 'project/invoice/invoiceListLoading',
      invoiceList: 'project/invoice/invoiceList',
      showAddPoNumberModal: 'project/invoice/showAddPoNumberModal',
      showLinkInvoiceModal: 'project/invoice/showLinkInvoiceModal',
      invoiceOverviewLoading: 'project/invoice/invoiceOverviewLoading',
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasInvoice() {
      let bool = false

      forEach(this.invoiceList, (invoiceListData) => {
        if (invoiceListData.invoices.length > 0) {
          bool = true
        }
      })

      return bool
    },
  },
}
</script>
