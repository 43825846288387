<template>
  <div
    class="vms-invoices-list__item vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-bottom-medium vd-margin-top-medium"
  >
    <div class="vms-invoices-list__item--header row no-gutters">
      <div
        :class="[
          'vms-invoices-list__icon vms-invoices-list__icon--small vd-margin-right-large',
          showSchedule
            ? ' vd-icon-arrow-up-grey-black'
            : ' vd-icon-arrow-down-grey-black',
        ]"
        @click="toggleSchedule"
      ></div>

      <div class="vms-invoices-list__item--invoice-id" @click="toggleSchedule">
        <div class="vd-darker-grey">Invoice ID</div>
        <div>
          <span
            v-if="invoiceDetail.invoice_url"
            :href="invoiceDetail.invoice_url"
            class="vd-light-blue"
          >
            {{
              invoiceDetail.invoice_manual_id || invoiceDetail.invoice_number
            }}
          </span>
          <span v-else>--</span>
        </div>
      </div>

      <a
        v-if="invoiceDetail.invoice_url"
        :href="invoiceDetail.invoice_url"
        class="vd-a"
        target="_blank"
        rel="noopener"
      >
        <span class="vms-invoices-list__icon vd-icon-eye"></span>
      </a>

      <div v-click-outside="closeInvoiceDropdown" class="vd-position-relative">
        <span
          v-if="canShowInvoiceDropdown"
          class="vms-invoices-list__icon vd-icon-three-dot vd-margin-left-small"
          @click="toggleInvoiceDropdown"
        ></span>
        <invoices-list-dropdown
          v-if="showInvoiceDropdown"
          :invoice-details="invoiceDetail"
          :order-details="orderDetail"
          @click="closeInvoiceDropdown"
        ></invoices-list-dropdown>
      </div>
    </div>

    <hr class="vd-border-grey" />

    <div class="row no-gutters">
      <div class="col-4">
        <div class="vd-margin-right-small">
          <div class="vd-darker-grey small">Price (ex GST)</div>
          <div>{{ invoiceDetail.price | moneyFormat }}</div>
        </div>
      </div>

      <div class="col-4 vd-text-align-center">
        <span v-if="isToBeInvoiced">
          {{ invoiceStatus }}
        </span>

        <div
          v-else
          :class="[
            'vd-margin-right-small vd-border-radius-50 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-small vd-padding-right-small vd-margin-top-small',
            statusClass,
          ]"
        >
          {{ invoiceStatus }}
        </div>
      </div>

      <div class="col-4 vd-text-align-right">
        <div v-if="orderDetail.po_number">
          {{ orderDetail.po_number }}
        </div>
        <button
          v-else-if="!isPaid && !showPoForm"
          class="vms-add-po-btn vd-btn-grey vd-padding-extra-small vd-padding-left-small vd-padding-right-small vd-light-blue small vd-margin-top-small"
          @click="togglePoForm"
        >
          + Add PO #
        </button>
      </div>
    </div>

    <div v-if="showPoForm" class="vd-margin-top-medium">
      <div
        class="vd-background-lightest-grey vd-padding-medium vd-border-radius-6"
      >
        <div class="vd-text-align-right">
          <span
            id="closeModalSpan"
            class="vd-icon-close vms-invoices-list__icon--small"
            @click="togglePoForm"
          ></span>
        </div>
        <input-component
          type="text"
          name="referenceNumber"
          label="Add/Update PO Number"
          :required="true"
          :container-custom-classes="[
            setInputValidationClass('referenceNumber'),
          ]"
          @formInput="handleInputUpdate($event, 'referenceNumber')"
        ></input-component>

        <div class="vd-margin-top-medium vd-text-align-right">
          <button
            :disabled="!referenceNumber || addPoNumberLoading"
            class="vd-btn-small vd-btn-blue"
            @click="savePo"
          >
            {{ addPoNumberLoading ? 'Loading...' : 'Save PO#' }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="showSchedule">
      <hr class="vd-border-grey" />

      <div class="row no-gutters">
        <div class="col-6">
          <div class="vd-darker-grey small">Scheduled Date</div>
          <div v-if="invoiceDetail.scheduled_date">
            {{ invoiceDetail.scheduled_date | unixToFormat('DD MMM YYYY') }}
          </div>
          <div v-else>--</div>
        </div>
        <div class="col-6">
          <div class="vd-darker-grey small">Due Date</div>
          <div v-if="invoiceDetail.due_date">
            {{ invoiceDetail.due_date | unixToFormat('DD MMM YYYY') }}
          </div>
          <div v-else>--</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import momentTz from 'moment-timezone'
import VmsConstants from '@configs/vms-constants'
import PermissionsMixin from '@mixins/permissions-mixin'
import InvoicesListDropdown from '@views/project/invoices/components/invoices-list-dropdown'
import ClickOutside from 'vue-click-outside'
import { includes } from 'lodash'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    InputComponent,
    InvoicesListDropdown,
  },

  directives: {
    ClickOutside,
  },

  mixins: [FormMixin, FiltersMixin, PermissionsMixin],

  props: {
    invoiceDetail: {
      type: Object,
      required: true,
    },

    orderDetail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      referenceNumber: '',
      showInvoiceDropdown: false,
      showSchedule: false,
      showPoForm: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      addPoNumberLoading: 'project/invoice/addPoNumberLoading',
    }),

    canShowInvoiceDropdown() {
      const notAllowedStatus = [
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT,
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_ON_HOLD,
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_CANCELLED,
      ]

      const isProjectActive = !includes(
        notAllowedStatus,
        this.project.project_status_id
      )

      return this.can('order_allow_restricted_actions') && isProjectActive
    },

    isPaid() {
      return (
        this.invoiceDetail.invoice_status_id ===
        VmsConstants.invoiceStatuses.XERO_INVOICE_STATUS_ID_PAID
      )
    },

    isOverdue() {
      // Convert invoice due date in moment unix.
      const dueDate = momentTz.unix(this.invoiceDetail.due_date)
      const now = momentTz()

      // Returns true if due date is before current date.
      return momentTz(dueDate).isBefore(now)
    },

    isToBeInvoiced() {
      // Invoice status will be empty if it's "to be invoiced".
      return this.invoiceDetail.invoice_status_id === ''
    },

    invoiceStatus() {
      if (this.isToBeInvoiced) {
        return this.invoiceDetail.invoice_status_name
      } else if (!this.isPaid && this.isOverdue) {
        return 'Overdue'
      } else {
        return this.invoiceDetail.invoice_status_name
      }
    },

    statusClass() {
      // Default status color.
      let statusClass = ''

      if (this.isPaid) {
        statusClass = 'vd-background-light-green vd-green'
      } else if (this.isOverdue && !this.isToBeInvoiced) {
        statusClass = 'vd-background-light-red vd-red'
      }

      return statusClass
    },
  },

  validations: {
    referenceNumber: {
      required,
    },
  },

  watch: {
    addPoNumberLoading(newVal) {
      if (!newVal) {
        this.showPoForm = false
      }
    },
  },

  methods: {
    ...mapActions({
      addPoNumber: 'project/invoice/addPoNumber',
    }),

    toggleInvoiceDropdown() {
      this.showInvoiceDropdown = !this.showInvoiceDropdown
    },

    closeInvoiceDropdown() {
      this.showInvoiceDropdown = false
    },

    toggleSchedule() {
      this.showSchedule = !this.showSchedule
    },

    togglePoForm() {
      this.showPoForm = !this.showPoForm
    },

    savePo() {
      const payload = {
        order_id: this.orderDetail.order_id,
        reference_number: this.referenceNumber,
      }

      this.addPoNumber(payload)
    },
  },
}
</script>
