<template>
  <div class="vms-invoices-list">
    <div v-for="(orderDetails, index) in invoiceList" :key="index">
      <project-invoices-card
        v-for="(invoiceDetail, cardIndex) in orderDetails.invoices"
        :key="cardIndex"
        :invoice-detail="invoiceDetail"
        :order-detail="orderDetails"
      ></project-invoices-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectInvoicesCard from '@views/project/invoices/project-invoices-list/project-invoices-card'

export default {
  components: {
    ProjectInvoicesCard,
  },

  computed: {
    ...mapGetters({
      invoiceList: 'project/invoice/invoiceList',
    }),
  },
}
</script>
