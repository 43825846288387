<template>
  <tr>
    <td class="vd-border-light-grey">
      <a
        :href="invoiceDetail.invoice_url"
        class="vd-a vms-invoices__view-invoice-link"
        target="_blank"
        rel="noopener"
      >
        {{ invoiceDetail.invoice_manual_id || invoiceDetail.invoice_number }}
      </a>
    </td>
    <td class="vd-border-light-grey">
      <span v-if="invoiceDetail.scheduled_date">
        {{ invoiceDetail.scheduled_date | unixToFormat('DD MMM YYYY') }}
      </span>
    </td>
    <td class="vd-border-light-grey">
      <span v-if="invoiceDetail.due_date">
        {{ invoiceDetail.due_date | unixToFormat('DD MMM YYYY') }}
      </span>
    </td>
    <td class="vd-border-light-grey">
      {{ invoiceDetail.price | moneyFormat }}
    </td>
    <td
      class="vd-border-light-grey vms-invoices__table--invoice-status"
      :class="statusColor"
    >
      {{ invoiceStatus }}
    </td>
    <td class="vd-border-light-grey">
      <div v-if="orderDetail.po_number" class="vd-black">
        {{ orderDetail.po_number }}
      </div>
      <button
        v-else-if="!isPaid"
        class="vms-add-po-btn vd-btn-small vd-btn-grey vd-margin-right-small vd-light-blue"
        @click="handleAddPoBtn"
      >
        + Add PO #
      </button>
    </td>
    <td class="vd-border-light-grey">
      <div
        v-if="canShowInvoiceDropdown"
        v-click-outside="closeInvoiceDropdown"
        class="vms-invoices__actions--container vd-position-relative"
      >
        <span
          class="text-left vms-invoices__actions"
          @click="toggleInvoiceDropdown"
        ></span>
        <invoices-list-dropdown
          v-if="showInvoiceDropdown"
          :invoice-details="invoiceDetail"
          :order-details="orderDetail"
        ></invoices-list-dropdown>
      </div>

      <a
        v-if="
          !can('order_allow_restricted_actions') && invoiceDetail.invoice_url
        "
        :href="invoiceDetail.invoice_url"
        class="vd-btn-small vd-margin-right-small pay-invoice-btn vd-btn-blue vms-invoices__view-invoice-button-link"
        role="button"
        target="_blank"
        rel="noopener"
      >
        View
      </a>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import momentTz from 'moment-timezone'
import VmsConstants from '@configs/vms-constants'
import PermissionsMixin from '@mixins/permissions-mixin'
import InvoicesListDropdown from '@views/project/invoices/components/invoices-list-dropdown'
import { includes } from 'lodash'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    InvoicesListDropdown,
  },

  directives: {
    ClickOutside,
  },

  mixins: [FiltersMixin, PermissionsMixin],

  props: {
    invoiceDetail: {
      type: Object,
      required: true,
    },

    orderDetail: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showInvoiceDropdown: false,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
    }),

    canShowInvoiceDropdown() {
      const notAllowedStatus = [
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT,
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_ON_HOLD,
        VmsConstants.projectStatuses.PROJECT_STATUS_ID_CANCELLED,
      ]

      const isProjectActive = !includes(
        notAllowedStatus,
        this.project.project_status_id
      )

      return this.can('order_allow_restricted_actions') && isProjectActive
    },

    isPaid() {
      return (
        this.invoiceDetail.invoice_status_id ===
        VmsConstants.invoiceStatuses.XERO_INVOICE_STATUS_ID_PAID
      )
    },

    isOverdue() {
      // Convert invoice due date in moment unix.
      const dueDate = momentTz.unix(this.invoiceDetail.due_date)
      const now = momentTz()

      // Returns true if due date is before current date.
      return momentTz(dueDate).isBefore(now)
    },

    isToBeInvoiced() {
      // Invoice status will be empty if it's "to be invoiced".
      return this.invoiceDetail.invoice_status_id === ''
    },

    invoiceStatus() {
      if (this.isToBeInvoiced) {
        return this.invoiceDetail.invoice_status_name
      } else if (!this.isPaid && this.isOverdue) {
        return 'Overdue'
      } else {
        return this.invoiceDetail.invoice_status_name
      }
    },

    statusColor() {
      // Default status color.
      let color = 'vd-black'

      if (this.isPaid) {
        color = 'vd-green'
      } else if (this.isOverdue && !this.isToBeInvoiced) {
        color = 'vd-red'
      }

      return color
    },
  },

  methods: {
    ...mapActions({
      toggleAddPoNumberShowModal: 'project/invoice/toggleAddPoNumberShowModal',
      updateAddPoNumberOrderIdModal:
        'project/invoice/updateAddPoNumberOrderIdModal',
    }),

    handleAddPoBtn() {
      this.toggleAddPoNumberShowModal()
      this.updateAddPoNumberOrderIdModal(this.orderDetail.order_id)
    },

    toggleInvoiceDropdown() {
      this.showInvoiceDropdown = !this.showInvoiceDropdown
    },

    closeInvoiceDropdown() {
      this.showInvoiceDropdown = false
    },
  },
}
</script>
